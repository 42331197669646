import CrossSaving from "../loans/loan-start-saving/LoanStartSaving";
import CrossBanner from "./cross-banner/CrossBanner";
import CrossBenefits from "./cross-benefits/CrossBenefits";
import CrossChoose from "./cross-choose/CrossChoose";

export default function CrossBorderPayments() {
    return(
        <>
            <CrossBanner/>
            <CrossChoose/>
            <CrossBenefits/>
            <CrossSaving/>
        </>
    )
}