import "./whyChoose.scss"
import piggyBank from "../../../assets/images/piggy-bank.svg"
import percentage from "../../../assets/images/percentage.svg"
import secure from "../../../assets/images/secure.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WhyChoose() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    const whyChooseList = [
        {
            icon: piggyBank,
            title: "Custom Savings Options",
            description: "We do the right thing and are honest in everything we do. Integrity isn’t just a word for us, it is our DNA."
        },
        {
            icon: percentage,
            title: "Great Interest Rates",
            description: "Our accounts offer some of the best rates around to get your money growing. We give you the most value for your money"
        },
        {
            icon: secure,
            title: "Security",
            description: "Your cash is safe with us. Our accounts are insured, so your money’s protected"
        }
    ]
    return(
        <>
            <div className="choose_wrapper" style={{paddingTop: "0"}}>
                <div className="container">
                    <div className="title_wrap">
                        <h2>Why Choose Azamra Deposit Accounts</h2>
                    </div>
                    <ul className="list-none grid-container-3">
                        {
                            whyChooseList.map(({icon,title,description},index)=>{
                                return(
                                    <li key={index}>
                                        <section>
                                            <figure><img src={icon} alt="piggy-bank"/></figure>
                                            <h3>{title}</h3>
                                            <p>{description}</p>
                                        </section>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}