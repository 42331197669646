import speedy from "../../../assets/images/speedy.svg"
import dollar from "../../../assets/images/dollar.svg"
import solution from "../../../assets/images/solution.svg"
import reach from "../../../assets/images/solution.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CrossChoose() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    const whyChooseList = [
        {
            icon: speedy,
            title: "Speedy Transactions",
            description: "Time is money, right? Traditional banks are super slow and can take days to get things done. With Azamra, your money moves much faster. Our blockchain technology ensures quick transactions, so you won’t be stuck waiting. Say goodbye to delays and hello to fast payments"
        },
        {
            icon: dollar,
            title: "Cheaper Costs",
            description: "Big fees can mess with your profits, especially with old-school banks. Azamra uses blockchain and stablecoins to reduce fees, so you save more money. We eliminate the middlemen, ensuring you retain more of your business earnings"
        },
        {
            icon: solution,
            title: "Simple Solutions",
            description: "Cross-border payments can be a headache with changing exchange rates, hidden fees, and complicated steps. Azamra’s got you covered with a simple, clear process. You'll have complete transparency on costs, and our technology simplifies the process, reducing hassle"
        },
        {
            icon: reach,
            title: "Worldwide Reach",
            description: "Dealing with clients and suppliers everywhere? Azamra’s got a global network that supports many currencies and countries. You can handle transactions easily no matter where you do business. Expand your business and trade globally with confidence"
        }
    ]
    return(
        <>
            <div className="choose_wrapper" style={{paddingTop: "0"}}>
                <div className="container">
                    <div className="title_wrap">
                        <h2>Why Choose Azamra for Your Payments?</h2>
                    </div>
                    <ul className="list-none grid-container-2" data-aos="fade-up">
                        {
                            whyChooseList.map(({icon,title,description},index)=>{
                                return(
                                    <li key={index}>
                                        <section>
                                            <figure><img src={icon} alt="piggy-bank"/></figure>
                                            <h3>{title}</h3>
                                            <p>{description}</p>
                                        </section>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}