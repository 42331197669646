export const ErrorPage = () => {
    return (
        <div className="banner_wrapper deposit_wrapper soon_wrap">
            <div className="container">
                <section>
                    <h1 className="animate__animated animate__fadeInUp">Page Not Found</h1>
                </section>
            </div>
        </div>
    );
};
