import { NavLink, useLocation } from "react-router-dom";
import angleDown from "../../assets/images/angle-down.svg"
import "./header.scss"
import logoImage from "../../assets/images/logo.svg"
import { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import GoogleTranslate from "./GoogleTranslate";

export default function MobHeader() {
    const [showSubMenu, setSubShowMenu] = useState(false);
    const { pathname } = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const openMenu = () => {
        setShowMenu(!showMenu);
    }
    const isActive = ['/deposits', '/loans', '/digital-wallets'].includes(pathname);
    return(
        <>
            <div className="header_wrap header_wrap_mobile">
                <div className="container flex justify-between items-center">
                    <NavLink to="/"><img src={logoImage} alt="logo"/></NavLink>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setShowMenu(false);
                        }}
                    >
                        <button onClick={openMenu} className={`menu_btn ${showMenu ? "active" : ''}`}>
                            <span></span>
                        </button>
                        <div className={`navigation_wrap ${showMenu ? "active" : ""}`}>
                            <ul className="list-none flex direction-column">
                                <li><NavLink onClick={()=>setShowMenu(false)} exact activeClassName="active" to="/">Home</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/about-us">About Us</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/cross-border-payments">Cross Border Payments</NavLink></li>
                                <li>
                                    <NavLink
                                        onClick={()=> setSubShowMenu(!showSubMenu)}
                                        to="javascript:void(0)"
                                        className={`${isActive ? "active" : ''} ${showSubMenu ? "open_drop" : ''}`}
                                    >
                                        Our Products
                                        <img src={angleDown} alt="angleDown"/>
                                    </NavLink>
                                    {showSubMenu &&
                                        <div className="navigation_drop">
                                            <NavLink
                                                activeClassName="active"
                                                onClick={()=> {
                                                    setSubShowMenu(false);
                                                    setShowMenu(false)
                                                }}
                                                to="/deposits"
                                            >
                                                Deposits
                                            </NavLink>
                                            <NavLink
                                                activeClassName="active"
                                                onClick={()=> {
                                                    setSubShowMenu(false);
                                                    setShowMenu(false)
                                                }}
                                                to="/loans"
                                            >
                                                Loans
                                            </NavLink>
                                            <NavLink
                                                activeClassName="active"
                                                onClick={()=> {
                                                    setSubShowMenu(false);
                                                    setShowMenu(false)
                                                }}
                                                to="/digital-wallets"
                                            >
                                                Digital Wallets
                                            </NavLink>
                                        </div>
                                    }
                                </li>
                                <li><NavLink onClick={()=>setShowMenu(false)} to="javascript:void(0)">Login</NavLink></li>
                                <li><GoogleTranslate/></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} to="/contact" className="header_btn">Contact Us</NavLink></li>
                            </ul>
                        </div>
                    </OutsideClickHandler>
                </div>
            </div>
        </>
    )
}