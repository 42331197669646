export default function ContactBanner() {
    return(
        <>
            <div className="banner_wrapper deposit_wrapper contact_bg">
                <div className="container">
                    <section className="animate__animated animate__fadeInUp">
                        <h1>Connect With Us</h1>
                        <p style={{marginBottom: "0"}}>If you have any questions, comments, or concerns, feel free to reach out to us. We’d love to hear from you!</p>
                    </section>
                </div>
            </div>
        </>
    )
}