import Banner from "./banner/Banner";
import Digital from "./digital/Digital";
import Services from "./services/Services";
import mapImage from "../../assets/images/map.png"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Home() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <Banner/>
            <Services/>
            <Digital/>
            <div className="map_wrap" data-aos="fade-up">
                <div className="container">
                    <img src={mapImage} alt="mapImage" />
                </div>
            </div>
        </>
    )
}