import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import story from "../../../assets/images/story.jpg"

export default function Story() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="values_wrapper story_wrap">
                <div className="container">
                    <div className='story_wrapper'>
                        <section data-aos="fade-left">
                            <h2>AZAMRA’S ORIGIN STORY</h2>
                            <p>During her childhood in Cameroon, Azamra Founder spent countless hours in the front room of her family’s home listening to her father and his friends, who met regularly to discuss business opportunities.  Leah was particularly influenced by <Link to="https://www.afrilandfirstgroup.com/index.php/en/boards-of-directors/5026-abdelhakim-ben-hammouda-2" target='_blank'>Dr. Paul Fokam</Link> Kammogne who often spoke about the importance of helping all Africans get access to adequate banking and financial services. </p>
                            <p>This informal group of ‘uncles’ would go on to become Cameroon’s business leaders and largest business consortium, eventually <b>controlling over 50% of the country’s GDP</b></p>
                            <p>Dr. Fokam went on to become the founder of <Link to="https://en.wikipedia.org/wiki/Afriland_First_Bank" target='_blank'>Afriland First Bank,</Link> Cameroon’s leading bank with over USD 3 billion in Total Assets (2021)</p>
                        </section>
                        <figure data-aos="fade-right">
                            <img src={story} alt="story"/>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}