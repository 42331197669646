import DigitalBanner from "./digital-banner/DigitalBanner";
import DigitalBoxes from "./digital-boxes/DigitalBoxes";
import DigitalSaving from "./digital-saving/DigitalSaving";
import WhyAzamra from "./why-azamra/WhyAzamra";

export default function DigitalWallets() {
    return(
        <>
            <DigitalBanner/>
            <DigitalBoxes/>
            <WhyAzamra/>
            <DigitalSaving/>
        </>
    )
}