import chose1 from "../../../assets/images/benefits.jpg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CrossBenefits() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="loan_chose_wrap" data-aos="fade-up">
                <div className="container flex items-center">
                    <section>
                        <h2>Experience Azamra’s <br/>Benefits</h2>
                        <ul className="list-none">
                            <li><span>Instant Transactions:</span> Move money across borders fast</li>
                            <li><span>Lower Rates:</span> Save on fees compared to traditional methods</li>
                            <li><span>Easy Interface:</span> Navigate and manage payments without fuss</li>
                            <li><span>Top Security:</span> Blockchain technology protects your transactions</li>
                        </ul>
                    </section>
                    <img src={chose1} alt="chose1"/>
                </div>
            </div>
        </>
    )
}