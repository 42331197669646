import { Link } from "react-router-dom";

export default function DigitalBanner() {
    return(
        <>
            <div className="banner_wrapper deposit_wrapper deposit_wrapper_bg">
                <div className="container">
                <section>
                        <h1 className="animate__animated animate__fadeInUp">Get Your Cashless Transactions Moving Fast with Azamra</h1>
                        <p className="animate__animated animate__fadeInUp">Azamra’s digital wallet makes cashless transactions smooth for everyone. Whether you're managing personal finances or running a business, our digital wallet offers exceptional features to make handling money fast, secure, and effortless.</p>
                        <Link href="/" className="header_btn">Download Azamra Wallet app</Link>
                    </section>
                </div>
            </div>
        </>
    )
}