import LoanBanner from "./loan-banner/LoanBanner";
import LoanChose from "./loan-chose/LoanChose";
import LoanOptions from "./loan-options/LoanOptions";
import LoanStartSaving from "./loan-start-saving/LoanStartSaving";

export default function Loans(params) {
    return(
        <>
            <LoanBanner/>
            <LoanOptions/>
            <LoanChose/>
            <LoanStartSaving/>
        </>
    )
}