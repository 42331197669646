import "./loanOption.scss"
import loan1 from "../../../assets/images/loan1.jpg"
import loan2 from "../../../assets/images/loan2.jpg"
import loan3 from "../../../assets/images/loan3.jpg"
import loan4 from "../../../assets/images/loan4.jpg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function LoanOptions() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="loan_options_wrap">
                <div className="container">
                    <div className="loan_heading">
                        <section>
                            <h2>Asset Financing</h2>
                            <h3>Upgrade Your Gear and Get More Done</h3>
                        </section>
                        <p>If your equipment is holding you back, our Asset Financing can help you upgrade with new gear or technology, allowing you to work faster and more efficiently. With flexible terms and competitive rates, you can invest in your business needs without disrupting your cash flow</p>
                    </div>
                    <div className="loan_option_content">
                        <img src={loan1} alt="loan" data-aos="fade-right"/>
                        <ul className="list-none" data-aos="fade-left">
                            <li><span>Flexible Repayment Terms:</span> Fits business cycle.</li>
                            <li><span>Quick Processing:</span> No long waits for cash.</li>
                            <li><span>Competitive Rates:</span> Affordable loans</li>
                        </ul>
                    </div>
                    <div className="loan_heading rev" data-aos="fade-up">
                        <p>You need steady cash to keep things going day-to-day. Our Working Capital Loans give you the funds for inventory, keeping cash flow smooth. With Azamra, your business stays on track, allowing you to focus on growing.</p>
                        <section>
                            <h2>Working <br/>Capital Loans</h2>
                            <h3>Keep Your Business Running Smoothly</h3>
                        </section>
                    </div>
                    <div className="loan_option_content rev">
                        <ul className="list-none" data-aos="fade-right">
                            <li><span>Fast Access to Funds:</span> Quick cash for urgent Needs</li>
                            <li><span>Flexible Usage:</span> Use it for whatever you need</li>
                            <li><span>Simple Application Process:</span> Less paperwork, easier approval.</li>
                        </ul>
                        <img src={loan2} alt="loan" data-aos="fade-left"/>
                    </div>
                    <div className="loan_heading" data-aos="fade-up">
                        <section>
                            <h2>Collateralized <br/>Trade Finance</h2>
                            <h3>Funds for Global Trade</h3>
                        </section>
                        <p>Looking to expand globally? Our Collateralized Trade Finance can get you the money you need for international ventures. Whether it’s purchasing inventory or managing exports/imports, Azamra has you covered, helping you enter the global trade arena with confidence.</p>
                    </div>
                    <div className="loan_option_content">
                        <img src={loan3} alt="loan" data-aos="fade-right"/>
                        <ul className="list-none" data-aos="fade-left">
                            <li><span>Enhance Credit Line:</span> Get funding with less hassle</li>
                            <li><span>Tailored Solutions:</span> Fits your specific trade needs</li>
                            <li><span>Global Trade Support:</span> Helps with transactions and managing risks</li>
                        </ul>
                    </div>
                    <div className="loan_heading rev" data-aos="fade-up">
                        <p>We know MSMEs sometimes struggle with regular credit. Azamra is here to provide personalized loans and support tailored to your needs. We’re not just offering loans; we’re committed to guiding you through the financial world and help you achieve your growth targets</p>
                        <section>
                            <h2>Support for <br/>MSMEs</h2>
                            <h3>Helping You Grow</h3>
                        </section>
                    </div>
                    <div className="loan_option_content rev">
                        <ul className="list-none" data-aos="fade-right">
                            <li><span>Personalized Service:</span> We’re here for you through the whole loan process</li>
                            <li><span>Comprehensive Financial Solutions:</span> More than just loans – we offer guidance</li>
                            <li><span>Empowering Your Business:</span> Helping you reach your full potential</li>
                        </ul>
                        <img src={loan4} alt="loan" data-aos="fade-left"/>
                    </div>
                </div>
            </div>
        </>
    )
}