import React, { useState, useEffect } from 'react';
import "./header.scss";
import MobHeader from "./MobHeader";
import HeaderNav from "./HeaderNav";

export default function Header() {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 999); // Adjust breakpoint as needed
    };

    useEffect(() => {
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize); // Update on resize
        return () => window.removeEventListener('resize', handleResize); // Cleanup
    }, []);

    return (
        <div className="header_wrap">
            {!isMobile && <HeaderNav />}
            {isMobile && <MobHeader />}
        </div>
    );
}
