import "./values.scss"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import integrity from "../../../assets/images/integrity.svg"
import financialInnovation from "../../../assets/images/financial-innovation.svg"
import ownership from "../../../assets/images/ownership.svg"
import leadership from "../../../assets/images/leadership.svg"

export default function Values() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="values_wrapper values_wrapper2">
                <div className="container">
                    <h2 data-aos="fade-up">Our Values</h2>
                    <ul className="list-none grid-container-2">
                        <li data-aos="fade-up">
                            <section>
                                <img src={integrity} alt="integrity"/>
                                <h3>Integrity</h3>
                                <p>We do the right thing and are honest in everything we do. Integrity isn’t just a word for us, it is our DNA.</p>
                            </section>
                        </li>
                        <li data-aos="fade-up">
                            <section>
                                <img src={financialInnovation} alt="financial"/>
                                <h3>Financial innovation</h3>
                                <p>We use the latest technology to improve access to financial services. We’re always looking for innovative ways to provide our customers with the best tools</p>
                            </section>
                        </li>
                        <li data-aos="fade-up">
                            <section>
                                <img src={ownership} alt="ownership"/>
                                <h3>Ownership</h3>
                                <p>We own our work and take pride in it. No matter the challenge, we put in the effort to ensure it’s done right</p>
                            </section>
                        </li>
                        <li data-aos="fade-up">
                            <section>
                                <img src={leadership} alt="leadership"/>
                                <h3>Leadership</h3>
                                <p>We challenge the status quo.  We strive to push boundaries and discover new ways to make an impact</p>
                            </section>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}