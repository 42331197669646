import "./whyAzamra.scss"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WhyAzamra() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="why_azamra_wrap">
                <div className="container">
                    <div className="title_wrap" data-aos="fade-up">
                        <h2>Why Choose Azamra?</h2>
                    </div>
                    <ul className="list-none">
                        <li data-aos="fade-up">
                            <div className="why_azamra_content">
                                <section>
                                    <h2>Financial Inclusion for Everyone</h2>
                                    <p>Azamra’s all about helping people who don’t get much from banks. Our digital wallet is user-friendly, making cashless transactions accessible to everyone, regardless of their banking situation.</p>
                                </section>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="why_azamra_content">
                                <section>
                                    <h2>Better Security</h2>
                                    <p>We take your security seriously. Azamra uses top-notch encryption and security to keep your transactions and information safe. Feel secure knowing your data’s protected from fraud and hackers</p>
                                </section>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="why_azamra_content">
                                <section>
                                    <h2>Easy to Use</h2>
                                    <p>Azamra’s app is super simple to navigate. Whether you’re a tech pro or new to digital payments, our clean design and easy features make managing your money a breeze.</p>
                                </section>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="why_azamra_content">
                                <section>
                                    <h2>Reliable Support</h2>
                                    <p>Our support team’s here to help you with everything. From setting up your account to fixing problems, we’re dedicated to making sure you get the most out of Azamra</p>
                                </section>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}