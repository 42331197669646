import "./depositeServe.scss"
import depositeServe1 from "../../../assets/images/deposite_serve1.png"
import depositeServe2 from "../../../assets/images/deposite_serve2.png"
import depositeServe3 from "../../../assets/images/deposite_serve3.png"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function DepositeServe() {
    useEffect(() => {
        AOS.init({
          duration: 1200,
          once: true, // Animation duration
        });
      }, []);
    const depositeServeList = [
        {
            image: depositeServe1,
            title: "Our Deposit Account Options",
            minTitle: "Term Deposit: Get More from Your Savings",
            description: "Want to make more from your savings? Our Term Deposit accounts give you higher rates if you keep your money in for a set time. With a Term Deposit, you’ll get:",
            listContent: [
                {
                    listTitle: "Fixed Rates:",
                    listDes: "Lock in your deposit for a higher rate of return.",
                },
                {
                    listTitle: "Safety:",
                    listDes: "Your money’s secure, and you’ll get a guaranteed return.",
                },
                {
                    listTitle: "Flexible Terms:",
                    listDes: "Pick a term length that fits your plans.",
                },
            ],
            destwo: "Start with a Term Deposit and see your savings grow with the security of a solid investment."
        },
        {
            image: depositeServe2,
            title: "Non-Term Deposit: Flexibility at Its Best",
            description: "Need easy access to your cash? Our Non-Term Deposit accounts give you the perfect mix of access and growth:",
            listContent: [
                {
                    listTitle: "Access Anytime:",
                    listDes: "Deposit and take out money you want, No penalties.",
                },
                {
                    listTitle: "Daily Interest:",
                    listDes: "Your money earns interest every day.",
                },
                {
                    listTitle: "No Fees:",
                    listDes: "No maintenance fees or hidden charges.",
                },
            ],
            destwo: "Get a Non-Term Deposit account and enjoy managing your savings however you want."
        },
        {
            image: depositeServe3,
            title: "Home Savings: Get Closer to Owning a Home",
            description: "Want a house or saving for a big goal? Our Home Savings accounts help you reach those dreams:",
            listContent: [
                {
                    listTitle: "Daily Interest:",
                    listDes: "Watch your savings grow with daily interest.",
                },
                {
                    listTitle: "Insurance:",
                    listDes: "Your money is insured, so you can relax.",
                },
                {
                    listTitle: "Dedicated Savings:",
                    listDes: "Designed to help you reach key milestones.",
                },
            ],
            destwo: "Open a Home Savings account and start making your dreams come true."
        }
    ]
    return(
        <>
            <div className="deposite_serve_wrapper">
                <div className="container">
                    {
                        depositeServeList.map(({image,title,minTitle,description,listContent,destwo},index)=>{
                            return(
                                <div className="deposite_serve_content" key={index}>
                                    <figure data-aos="fade-left"><img src={image} alt="img"/></figure>
                                    <section data-aos="fade-right">
                                        <h2>{title}</h2>
                                        <h3>{minTitle}</h3>
                                        <p className="first">{description}</p>
                                        <ul className="list-none">
                                            {
                                                listContent.map((content, index)=>{
                                                    return(
                                                        <li key={`a-${index}`}>
                                                            <span>{content.listTitle}</span> {content.listDes}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <p>{destwo}</p>
                                    </section>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}