import ContactBanner from "./contact-banner/ContactBanner";
import ContactInfo from "./contact-info/ContactInfo";

export default function Contact() {
    return(
        <>
            <ContactBanner/>
            <ContactInfo/>
        </>
    )
}