import "./loanChose.scss"
import chose1 from "../../../assets/images/chose1.jpg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function LoanChose() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="loan_chose_wrap" data-aos="fade-up">
                <div className="container flex items-center">
                    <section>
                        <h2 className="animate__animated animate__fadeInUp">Why Choose Azamra?</h2>
                        <ul className="list-none">
                            <li><span>Trusted Partner:</span> Lots of experience with MSMEs</li>
                            <li><span>Customized Solutions:</span> Loans made for your needs.</li>
                            <li><span>Fast and Efficient:</span> Quick processing and flexible terms.</li>
                        </ul>
                    </section>
                    <img src={chose1} alt="chose1"/>
                </div>
            </div>
        </>
    )
}