import {
  Route,
  // createHashRouter,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { UserLayout } from "./UserLayout";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import Home from "../pages/landingpage/Home";
import OurCompany from "../pages/our-company";
import Deposits from "../pages/deposits";
import Loans from "../pages/loans";
import CrossBorderPayments from "../pages/cross-border-payments";
import DigitalWallets from "../pages/digital-wallets";
import Contact from "../pages/contact";

export const MainRouting = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" errorElement={<ErrorPage />} element={<UserLayout />}>
          <Route index element={<Home />}></Route>
          <Route path="/about-us" element={<OurCompany />}></Route>
          <Route path="/deposits" element={<Deposits />}></Route>
          <Route path="/loans" element={<Loans />}></Route>
          <Route path="/digital-wallets" element={<DigitalWallets />}></Route>
          <Route path="/cross-border-payments" element={<CrossBorderPayments />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};
