import "./banner.scss"
import bannerIcon1 from "../../../assets/images/bannericon1.svg"
import appBtn from "../../../assets/images/app.svg"
import googleBtn from "../../../assets/images/google.svg"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';


export default function Banner() {
    return(
        <>
            <div className="banner_wrapper home_banner">
                <Swiper
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="banner_slide">
                            <div className="container">
                                <section>
                                    <h1 className="animate__animated animate__fadeInUp">Empowering Africa’s Future with Digital <img src={bannerIcon1} alt="bannerIcon1"/> Financial Solutions</h1>
                                    <p className="animate__animated animate__fadeInUp">Azamra is revolutionizing financial services access in Central Africa through innovative solutions. By delivering essential financial services to millions and offering funding and digital tools to support MSMEs, Azamra is committed to fostering growth and advancing financial inclusion across the region.</p>
                                    <div className="banner_btns">
                                        <Link className="animate__animated animate__fadeInLeft" to="/"><img src={appBtn} alt="appBtn"/></Link>
                                        <Link className="animate__animated animate__fadeInRight" to="/"><img src={googleBtn} alt="googleBtn"/></Link>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="banner_slide slide2">
                            <div className="container">
                                <section>
                                    <h1 className="animate__animated animate__fadeInUp">Leading Provider of Digital Financial Solutions</h1>
                                    <p className="animate__animated animate__fadeInUp">We use the latest technology to improve access to financial services. We’re always looking for innovative ways to provide our customers with the best tools.</p>
                                    <div className="banner_btns">
                                        <Link className="animate__animated animate__fadeInLeft" to="/"><img src={appBtn} alt="appBtn"/></Link>
                                        <Link className="animate__animated animate__fadeInRight" to="/"><img src={googleBtn} alt="googleBtn"/></Link>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="banner_slide slide3">
                            <div className="container">
                                <section>
                                    <h1 className="animate__animated animate__fadeInUp">Faster, Cheaper Cross-Border Payments</h1>
                                    <p className="animate__animated animate__fadeInUp">Azamra leverages blockchain and stablecoins for fast, cost-effective cross-border payments, offering a smoother experience than traditional banks.</p>
                                    <div className="banner_btns">
                                        <Link className="animate__animated animate__fadeInLeft" to="/"><img src={appBtn} alt="appBtn"/></Link>
                                        <Link className="animate__animated animate__fadeInRight" to="/"><img src={googleBtn} alt="googleBtn"/></Link>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}