import { Link } from "react-router-dom"
import "./footer.scss"
import logoImage from "../../assets/images/white-logo.png"
import linkedinIcon from "../../assets/images/linkedin.png"
import facebookIcon from "../../assets/images/facebook.png"
import instaIcon from "../../assets/images/instagram.png"

export default function Footer() {
    return(
        <>
            <div className="footer_wrap">
                <div className="container">
                    <div className="new_footer">
                        <div className="logo_info">
                            <Link to="/"><img src={logoImage} alt="logoImage"/></Link>
                            <ul className="list-none flex">
                                <li><Link to="https://www.facebook.com/Azamrafintech/" target="_blank"><img src={facebookIcon} alt="face" /></Link></li>
                                <li><Link to="/" target="_blank"><img src={linkedinIcon} alt="linkedin" /></Link></li>
                                <li><Link to="/" target="_blank"><img src={instaIcon} alt="insta" /></Link></li>
                            </ul>
                        </div>
                        <div className="link_info">
                            <h4>Useful links</h4>
                            <ul className="list-none">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/cross-border-payments">Cross Border Payments</Link></li>
                                <li><Link to="#">Login</Link></li>
                            </ul>
                        </div>
                        <div className="link_info">
                            <h4>Useful links</h4>
                            <ul className="list-none">
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/deposits">Deposits</Link></li>
                                <li><Link to="/loans">Loans</Link></li>
                                <li><Link to="/digital-wallets">Digital Wallets</Link></li>
                            </ul>
                        </div>
                        <div className="contact_information">
                            <h4>Contact Info</h4>
                            <ul className="list-none">
                                <li><Link to="mailto:Info@Azamra.com"><i className="fa fa-envelope"></i>Info@Azamra.com</Link></li>
                                <li style={{margin: "16px 0 5px"}}><span><i class="fa fa-map-marker"></i>30 Wall Street, New York, NY 10005</span></li>
                                <li><Link to="tel:+12126344288"><i class="fa fa-phone"></i>+1 212 634 4288</Link></li>
                                <li style={{margin: "16px 0 5px"}}><span><i class="fa fa-map-marker"></i>Yaounde, PO Box 16110 Yaounde</span></li>
                                <li><Link to="tel:+237222203334"><i class="fa fa-phone"></i>+ 237 222 20 33 34</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright_wrap">
                        <p>Copyrights © 2024 All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}