import { Link } from "react-router-dom"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function DigitalSaving() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="start_saving_wrap">
                <div className="container">
                    <div className="start_saving" data-aos="fade-up">
                        <section>
                            <h2 style={{color: "#0036b8"}}>Get Started with Azamra Today</h2>
                            <p>Step into the future of managing your money with Azamra’s digital wallet. Enjoy fast transfers, easy payments, and simple bill management—all right from your phone</p>
                            <Link to="/" className="header_btn">Download Azamra now</Link>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}