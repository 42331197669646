import digitalImg1 from "../../../assets/images/digital-img5.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function DigitalBoxes() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="digital_wrapper digital_boxes">
                <div className="container">
                    <div className="title_wrap">
                        <h2>Transform Your Transactions with Our Core Services</h2>
                    </div>
                    <ul className="list-none">
                        <li>
                            <div className="digital_content">
                                <section>
                                    <h3>Quick Transfers: Fast and Easy</h3>
                                    <p>Send money to anyone super quick with Azamra Wallet. You can get your cash to friends or family in seconds. No more waiting around. Split a bill or send a gift—our app makes it super easy. No bank lines or long waits needed</p>
                                </section>
                                <img src={digitalImg1} alt="digitalImg1" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="digital_min_boxes">
                <div className="container">
                    <div className="mini_content" data-aos="fade-right">
                        <h2>Service Payments: Easy Bill Management</h2>
                        <p>Paying your bills, taxes, and other stuff is now a breeze. Azamra’s got a simple way to handle all your payments with just a few taps. No more messing with different payment apps or paperwork. Everything’s in one easy app</p>
                    </div>
                    <div className="mini_content" data-aos="fade-left">
                        <h2>Merchant Payments: Easy Shopping</h2>
                        <p>Forget those old POS machines and long checkout lines. With Azamra, you can pay for stuff at your favourite stores just using your wallet. It’s a total game changer for shopping. No more hassles with cash or cards.</p>
                    </div>
                </div>
            </div>
        </>
    )
}