import { Link } from "react-router-dom"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CrossSaving() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="start_saving_wrap">
                <div className="container">
                    <div className="start_saving" data-aos="fade-up">
                        <section>
                            <h2>Empower Your Business with Azamra</h2>
                            <h3>Apply Today and Start Your Journey to Success!</h3>
                            <p>Ready to level up your business? Apply for a Loan Now. Applying for a loan with Azamra is easy. Our team ensures a seamless process, allowing you to focus on expanding your business.</p>
                            <Link to="/" className="header_btn">Start Your Application</Link>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}