import React, { useEffect, useState } from "react";
import enFlag from "../../assets/images/en.jpg";
import frFlag from "../../assets/images/cameroon-flag.jpg";

const GoogleTranslate = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [isOtherDivVisible, setIsOtherDivVisible] = useState(false);

    const observeLanguageChange = () => {
        const observer = new MutationObserver(() => {
            const translateDropdown = document.getElementById('google_translate_element');
            const selectedLang = translateDropdown.querySelector('.goog-te-combo').value;

            if (selectedLang === 'fr') {
                setIsOtherDivVisible(true);
            } else {
                setIsOtherDivVisible(false);
            }
        });

        const translateDropdown = document.getElementById('google_translate_element');
        observer.observe(translateDropdown, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    };

    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

        // Start observing language change
        const cleanupObserver = observeLanguageChange();
        return () => {
            cleanupObserver();
        };
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: "en,fr",
                autoDisplay: true,
                onLanguageChanged: function (lang) {
                    setSelectedLanguage(lang);
                    updateDropdownText(lang);
                    setTimeout(() => {
                    window.location.reload();
                }, 100);
                }
            },
            "google_translate_element"
        );

        // Update the dropdown options initially
        setTimeout(() => {
            updateDropdownText();
        }, 1000); // Delay to ensure the options are rendered
    };

    const updateDropdownText = (selectedLang) => {
        const dropdown = document.querySelector('.goog-te-combo');
        if (dropdown) {
            const options = dropdown.options;
            options[0].text = "ENG"; // Change English to ENG
            options[1].text = "FR";  // Change French to FR
            
            // Set the dropdown to show "ENG" or "FR"
            if (selectedLang) {
                dropdown.value = selectedLang;
                dropdown.dispatchEvent(new Event('change')); // Trigger change event
            }
        }
    };

    useEffect(() => {
        const dropdown = document.querySelector('.goog-te-combo');
        if (dropdown) {
            dropdown.addEventListener('change', () => {
                const lang = dropdown.value;
                updateDropdownText(lang);
            });
        }
    }, [selectedLanguage]);

    return (
        <>
            <div className="d-flex flag-drop">
                {isOtherDivVisible ? (<img src={frFlag} alt="frFlag"/>) : (<img src={enFlag} alt="enFlag"/>)}
                <div id="google_translate_element"></div>
            </div>
        </>
    );
};

export default GoogleTranslate;
