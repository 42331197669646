import { Link } from "react-router-dom"
import "./contactInfo.scss"
import unitedStates from "../../../assets/images/united-states.svg"
import cameroonFlag from "../../../assets/images/cameroon-flag.svg"

export default function ContactInfo() {
    return(
        <>
            <div className="contact_info_wrap">
                <div className="container flex">
                    <div className="contact_form animate__animated animate__fadeInLeft">
                        <h2>Get In Touch</h2>
                        <ul className="list-none">
                            <li><input type="text" placeholder="Full Name" /></li>
                            <li><input type="text" placeholder="Email Address" /></li>
                            <li><input type="text" placeholder="Subject" /></li>
                            <li><textarea placeholder="Message"></textarea></li>
                            <li>
                                <button className="simple_btn">Submit</button>
                            </li>
                        </ul>
                    </div>
                    <div className="contact_us_info animate__animated animate__fadeInRight">
                        <h2>Contact Info</h2>
                        <ul className="list-none">
                            <li><Link to="mailto:Info@Azamra.com"><i className="fa fa-envelope"></i>Info@Azamra.com</Link></li>
                            <li>
                                <span><img src={unitedStates} alt="united-states" /> United States <br/>30 Wall Street, New York, NY 10005</span>
                                <Link to="tel:+12126344288"><i class="fa fa-phone"></i>+1 212 634 4288</Link>
                            </li>
                            <li>
                                <span><img src={cameroonFlag} alt="cameroonFlag" />Cameroon <br/>Yaounde, PO Box 16110 Yaounde</span>
                                <Link to="tel:+237222203334"><i class="fa fa-phone"></i>+ 237 222 20 33 34</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}