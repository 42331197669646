import Story from "./story/Story";
import Values from "./values/Values";
import WhyAzmara from "./why-azmara/WhyAzmara";

export default function OurCompany() {
    return(
        <>
            {/* <Vision/> */}
            <Story/>
            <Values/>
            <WhyAzmara/>
        </>
    )
}