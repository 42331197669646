import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WhyAzmara() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="values_wrapper why_azmara_wrap">
                <div className="container">
                    <h2 data-aos="fade-up">Why AZAMRA</h2>
                    <p data-aos="fade-up">Headquartered in New York City (USA), Azamra was founded in 2021 by a seasoned Wall Street banking executive who has leveraged her extensive banking experience and expertise to create a digital platform committed to improving financial inclusion and facilitating faster and more efficient financial transactions across Africa and the Middle East.</p>
                    <div className='story_wrapper'>
                        <section data-aos="fade-right">
                        <p>Azamra operates as a licensed digital first banking platform in Central Africa with plans to expand across Africa. Transactions are completed via apps and website with a small number of branches and kiosks available primarily for cash transactions and the establishment of new accounts.</p>
                            <ul className='list-none'>
                                <li>Fully-licensed digital first banking platform based in Cameroon</li>
                                <li>Authorized by the Central African Banking Commission to operate in 6 African countries (Cameroon, Gabon, Chad, Congo, Republic of Central Equatorial Guinea)</li>
                                <li>
                                    Azamra was established via:
                                    <ol>
                                        <li>The acquisition of Les Mutuelles Camerounaises d'Epargne et du Crédit (MUCADEC) microfinance bank which was set up by Credit Mutuel de France (One of France largest Financial Institution) in <b>2009</b> & licensed <b>in 2011</b></li>
                                        <li>MUCADEC was recapitalized, consolidated, and rebranded as Azamra</li>
                                    </ol>
                                </li>
                                <li>Soon to be licensed in Ivory Coast & Hong Kong to enable trade between Africa and Asia</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}